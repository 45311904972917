<template >
    <div >
        <PageTitle title="就诊记录"  backTo="/home" fixed ></PageTitle>
        <div style="position: relative;width: 85%;margin: 15px auto auto auto;" >
            <List :width="100"  >
                <ListItem :badgeNumber="item.icon" v-for="(item,index) in items" :key="index"  :to="'/visitmx/'+(item.jzxh!=null?item.jzxh:item.ddmb)+'/'+(item.jzxh!=null?1:0)+'/'+(item.dqzt.includes('已发货') ? 1 : 0)" >
                    <template #top>
                        <div style="height: 10px"></div>
                        <!--<p style="text-align: center;padding: 10px 0">状态：{{item.status==0?'准备中':(item.status==2?'待签收':'已发货')}}</p>-->
                        <div class="borderColor">
                            <div class="leftArea">
                                <p class="fontmd">就诊时间：{{SJPD(item.yysj)}}</p>
                                <p class="fontmd">接诊医师：{{item.jzys}}</p>
                                <p class="fontsm" >诊断：{{item.diagnosisList[0].jbmc}}</p>
                                <p class="fontsm">执业点：{{item.zyd}}</p>
                                <p class="fontsm" v-if="item.fy!=0">费用：{{item.fy}}元</p>
                                <p class="fontsm" v-else>费用：未结算</p>
                                <p class="fontsm" v-if="item.jzjlypxxes[0].ypmc!=null">药品名称：{{item.jzjlypxxes[0].ypmc}}   X{{item.jzjlypxxes[0].ypsl}}{{item.jzjlypxxes[0].yfdw}}</p>
                                <p class="fontsm" v-else>药品名称：暂无</p>
                                <p class="fontmd">当前状态：{{item.dqzt}}</p>
                                <p class="fontmd" v-if="item.kddh!=null">物流单号：{{item.kddh}}</p>
                            </div>
                            <div class="rightArea">
                            <van-icon name="arrow"/>
                             </div>
                        </div>
                    </template>
                </ListItem>
            </List>
            <p style="text-align: center;font-size:14px;color: #3f9775" v-if="this.showmore">暂无更多就诊信息</p>
        </div>
    </div>
</template>
<script>
    import PageTitle from '@/components/PageTitle.vue';
    import List from '@/components/home-page/List.vue';
    import ListItem from '@/components/home-page/ListItem.vue';
    import { GetJZLB } from '@/api/consult.js';
    import {getDefaultAccount} from'@/api/user.js';
    export default{
        components: {
            PageTitle,
            List,
            ListItem,
        },
        data(){
            return {
                items:[],
                ckhm:"",
                order: false,
                index:1,
                pd:true,
                showmore:false,
            }
        },
        created: function () {
            this.jzlb();
            this.handleScroll();
        },
        destroyed: function () {
            window.removeEventListener("scroll", this.handleScroll);
        },
        methods:{
            jzlb(){
               let that=this;
                getDefaultAccount(this.$store.getters.userInfo.UserId).then(
                    (result)=>{
                       this.ckhm=result.ckhm;
                        GetJZLB(this.ckhm,1).then(
                            (results)=>{
                                that.items=results;
                            },
                        () => {
                        },
                        )
                    },
                    ()=>{}
                );
                // 调用下面 compare 方法 并传值
            },
            GD(){
                   let that=this;
                   that.index++;
                    GetJZLB(this.ckhm, that.index).then(
                        (results) => {
                            // if(results.data.resultStatus=="200") {
                                that.items = that.items.concat(results);
                                that.pd=true;
//                                for (let i = 0; i < 5; i++) {
//                                    if (results[i]) {
//                                        that.items.push(results[i]);
//                                    }
//                                    else {
////                                    Vue.prototype.$toast(res.msg||"未查询到数据");
////                                    that.pd = false;
//                                    }
//                                }
//                             }
//                             else{
// //                                if(!that.pd&&document.documentElement.scrollTop + document.documentElement.clientHeight == document.documentElement.scrollHeight){
// //                                    Vue.prototype.$toast(results.data.msg||"未查询到数据");
// //                                }
//                                 that.pd=false;
//                                 this.showmore=true;
// //                                Vue.prototype.$toast(results.data.msg||"未查询到数据");
//                             }
                        },
                        () => {
//                            if(!that.pd&&document.documentElement.scrollTop + document.documentElement.clientHeight == document.documentElement.scrollHeight){
//                                Vue.prototype.$toast(res.msg||"未查询到数据");
//                            }
                          that.pd=false;
                          this.showmore=true;
                        },
                    )

            },
//            listenerFunction() {
//                let that=this;
//                window.onscroll = function(){
//                    //变量scrollTop是滚动条滚动时，距离顶部的距离
//                    var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
//                    //变量windowHeight是可视区的高度
//                    var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
//                    //变量scrollHeight是滚动条的总高度
//                    var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
//                    //滚动条到底部的条件
//                    if(scrollTop+windowHeight+10>= scrollHeight){
//                        //到了这个就可以进行业务逻辑加载后台数据了
//                        that.GD();
//                    }
//
//                }
//            },
            appSource() {
                const u = navigator.userAgent;
                const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
                if (isiOS) {
                    return "ios";
                } else {
                    return "andriod";
                }
            },
            handleScroll () {
                       let that=this;
//                 if(that.appSource=="andriod") {
                     window.onscroll = function () {
                         //变量scrollTop是滚动条滚动时，距离顶部的距离
                         var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                         //变量windowHeight是可视区的高度
                         var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                         //变量scrollHeight是滚动条的总高度
                         var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

                         //滚动条到底部的条件
                         if (scrollTop + windowHeight+50 >= scrollHeight&&that.pd) {
                             //到了这个就可以进行业务逻辑加载后台数据了
                             that.pd=false;
                             that.GD();
                        }
                     }
//                 }
//                 else if(that.appSource=="andriod"){
//                     if (document.documentElement.scrollTop + document.documentElement.clientHeight == document.documentElement.scrollHeight&&this.pd) {
//                         this.GD();
//                     }
//                 }
            },
           SJPD(e){
                return this.$util.moment(e).format("YYYY-MM-DD HH:mm:ss")
           }
        },
    }
</script>

<style scoped="" lang="less">
    @import "../style/var.less";

    .infoContainer{
        padding-bottom: 10px;
        p{
            margin: 0;
        }
        .borderColor{
            background-color: #f6f6f6;
            border-radius: 8px;
            padding: 10px;
            margin: 0 10px;

            .leftArea{
                width: 85%;
                display: inline-block;

            }
            .rightArea{
                width:15%;
                display: inline-block;
                vertical-align: top;
                p{
                    float: right;
                }
                .van-icon {
                    float: right;
                    margin-top: 40px;
                }
            }
        }
        .fontmd{
            font-size: @font-size-md;
        }
        .fontsm{
            font-size: @font-size-sm;
        }
    }

</style>